import { useEffect, useState } from "react";
import { Project } from "../../models/Project";
import CurrencyFormat from "../Format/CurrencyFormat";
import { useTranslation } from "react-i18next";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import projectServices from "../../services/project.service";
import { Popover } from "antd";
import TotalPartPrice from "./TotalPartPrice";
import SurfaceTreatmentSurcharge from "./SurfaceTreatmentSurcharge";
interface DefaultProps {
  project: Project;
}
function ProjectPrices(props: DefaultProps) {
  const { project } = props;
  const { t } = useTranslation();
  const [deliveryCost, setDeliveryCost] = useState(project.order?.price);
  const [downloadingPreviewOrder, setDownloadingPreviewOrder] = useState(false);

  useEffect(() => {
    setDeliveryCost(project.order?.price);
  }, [project]);

  const onDownloadPreviewOffer = async () => {
    try {
      setDownloadingPreviewOrder(true);
      await projectServices.downloadPreviewOrder({
        id: project.id,
        name: project.name || "GOCAD",
      });
    } catch (error) {}
    setDownloadingPreviewOrder(false);
  };

  if (!deliveryCost) return <></>;
  return (
    <div className="w-100 summary-price">
      <TotalPartPrice price={deliveryCost} />
      <SurfaceTreatmentSurcharge price={deliveryCost} />
      <div className="d-flex">
        <label>
          {t("price.deliveryOptionPrice")} (
          {t(`deliveryOption.${project.deliveryOption}`)})
        </label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.deliveryOptionPrice} />
        </label>
      </div>
      <div className="d-flex">
        <label>{t("price.packagingPrice")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.packagingPrice} />
        </label>
      </div>
      <div className="d-flex shipping-cost">
        <label>{t("price.shipping")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.shipping} />
        </label>
      </div>
      <div className="text-muted">
        <i>{t(`shippingOption.${project?.order?.shippingOption}`)}</i>
      </div>
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.netTotal")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.netTotal} />
        </label>
      </div>
      <div className="d-flex">
        <label>{t("price.vat")}</label>
        <label className="ms-auto">
          <CurrencyFormat value={deliveryCost.vat} />
        </label>
      </div>
      <hr />
      <div className="d-flex fw-bold">
        <label>{t("price.total")}</label>
        <label className="ms-auto total-price">
          <CurrencyFormat value={deliveryCost.total} />
        </label>
      </div>
      <hr />
      <div>
        <a onClick={onDownloadPreviewOffer}>
          <PdfFileIcon /> {t("project.checkout.previewOffer")}{" "}
          {downloadingPreviewOrder && <LoadingOutlined spin />}
        </a>
      </div>
    </div>
  );
}
export default ProjectPrices;
