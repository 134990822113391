import { useTranslation } from "react-i18next";

export const useRuleStringRequired = (required: boolean = true) => {
  const { t } = useTranslation();
  return [
    { required: required },
    {
      validator: (_: any, value: any) =>
        !(value && !value.trim())
          ? Promise.resolve()
          : Promise.reject(new Error(t("validate.required") || "")),
    },
  ];
};

export const useRulePassword = () => {
  const { t } = useTranslation();
  return [
    { required: true },
    {
      pattern:
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      message: t("validate.passwordRegex"),
    },
  ];
};
