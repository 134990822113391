import { Popover } from "antd";
import { Price } from "app/models/Price";
import CurrencyFormat from "../Format/CurrencyFormat";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface DefaultProps {
  price?: Price;
  lableClass?: string;
}

function TotalPartPrice(props: DefaultProps) {
  const { price, lableClass } = props;
  const { t } = useTranslation();

  return (
    <div className="d-flex">
      {!!price?.totalPartPriceSurcharge && (
        <>
          <Popover
            overlayStyle={{
              maxWidth: "20vw",
            }}
            placement="top"
            content={
              <>
                <div>
                  <table className="table mb-0 text-white">
                    <thead>
                      <tr>
                        <th scope="col">{t("method")}</th>
                        <th scope="col" className="text-end">
                          {t("price")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!price?.totalPartPriceSurcharge && (
                        <tr>
                          <td scope="col">{t("adaptMinimumTotalPartPrice")}</td>
                          <td scope="col" className="text-end">
                            <CurrencyFormat
                              value={price.totalPartPriceSurcharge}
                            />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </>
            }
            trigger="hover"
          >
            <label className={lableClass ?? lableClass}>
              {t("price.totalPartPrice")}: <InfoCircleOutlined />
            </label>
          </Popover>
          <label className="ms-auto">
            <CurrencyFormat value={price?.totalPartPrice} />
          </label>
        </>
      )}
      {!price?.totalPartPriceSurcharge && (
        <>
          <label className={lableClass ?? lableClass}>
            {t("price.totalPartPrice")}:
          </label>
          <label className="ms-auto">
            <CurrencyFormat value={price?.totalPartPrice} />
          </label>
        </>
      )}
    </div>
  );
}

export default TotalPartPrice;
