import { CuttingType } from "app/models/CuttingType";
import services from ".";
import { API } from "../constants/api.constant";
import { Material } from "../models/Material";
import { MaterialGroup } from "../models/MaterialGroup";
import { TableFilter } from "../models/Table";

export const create = async (data: Material) => {
  try {
    const rs = await services.post(API.MATERIALS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const list = async (params?: TableFilter) => {
  try {
    const rs = await services.get(API.MATERIALS_SEARCH, { params });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const detail = async (id: any) => {
  try {
    const rs = await services.get(API.MATERIAL_DETAIL.replace(":id", id));
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const update = async (data: Material) => {
  try {
    if (data?.id) {
      const rs = await services.put(API.MATERIALS, data);
      return rs.data;
    } else {
      throw Error("Update failed!");
    }
  } catch (error) {
    throw error;
  }
};

export const getMaterialGroups = async (params?: TableFilter) => {
  try {
    const rs = await services.get(API.MATERIAL_GROUPS, { params });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const getPartMaterialGroups = async (cuttingType: CuttingType) => {
  try {
    const rs = await services.get(API.PART_MATERIAL_GROUPS, {
      params: {
        cuttingType
      }
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const getMaterialGroupById = async (id: number) => {
  try {
    const rs = await services.get(
      API.MATERIAL_GROUPS_BY_ID.replace(":id", id.toString())
    );
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const updateMaterialGroups = async (data: MaterialGroup) => {
  try {
    const rs = await services.put(API.MATERIAL_GROUPS, data);
    return rs.data;
  } catch (error) {
    throw error;
  }
};

export const importMaterial = async (file: any) => {
  var formData = new FormData();
  formData.append("file", file);
  try {
    const rs = await services.post(API.MATERIAL_IMPORT, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return rs;
  } catch (error) {
    throw error;
  }
};

export const exportMaterial = async () => {
  try {
    const rs = await services.get(API.MATERIAL_EXPORT, {
      headers: {
        responseType: "blob",
      },
    });
    return rs.data;
  } catch (error) {
    throw error;
  }
};

const materialServices = {
  create,
  list,
  detail,
  update,
  getMaterialGroups,
  getMaterialGroupById,
  updateMaterialGroups,
  exportMaterial,
  importMaterial,
  getPartMaterialGroups,
};

export default materialServices;
