/* eslint-disable jsx-a11y/scope */
import { useTranslation } from "react-i18next";
import CurrencyFormat from "../Format/CurrencyFormat";
import { Form, Input, InputNumber } from "antd";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import InputInlineApi from "../Form/InputInlineApi";
import partServices from "app/services/part.service";
import Utils from "app/utils";
import { LoadingOutlined } from "@ant-design/icons";

interface DefaultProps {
  part?: any;
  data?: any;
  layout?: "light" | "dark";
  hasUpdateQuantity?: boolean;
  displayKeys?: any[];
  onQuantitySelected?: any;
  onQuantityChanged?: any;
}

function PriceReference(props: DefaultProps) {
  const {
    part,
    data,
    layout = "dark",
    displayKeys,
    onQuantitySelected,
    onQuantityChanged,
    hasUpdateQuantity = false,
  } = props;
  const { t } = useTranslation();
  const [quantityRefLoading, setQuantityRefLoading] = useState<any>({
    quantityRef1: false,
    quantityRef2: false,
    quantityRef3: false,
  });
  const onSelect = (key: any) => {
    if (onQuantitySelected) onQuantitySelected(key);
  };

  const onQuantityUpdated = (key: any) => async (value: any) => {
    setQuantityRefLoading({
      ...quantityRefLoading,
      [key]: true,
    });
    if (data) {
      const quantities: any[] = [];
      Object.keys(data).map((quantity: any, index) => {
        if (key == `quantityRef${index + 1}`) {
          quantities.push(value);
        } else {
          quantities.push(quantity);
        }
      });
      await partServices.updateReferenceCosts(part.id, quantities);
      if (onQuantityChanged) {
        await onQuantityChanged(value, quantities);
      }
    }
    setQuantityRefLoading({
      ...quantityRefLoading,
      [key]: false,
    });
  };

  return (
    <div
      className={`table-price-reference ${
        layout === "dark" ? "text-white" : "light"
      }`}
    >
      <p className="title text-label mb-1">{t("part.bulkPricing")}:</p>
      <table className={`table mb-0`}>
        <thead>
          <tr>
            <th scope="col">{t("quantity")}</th>
            <th scope="col" className="text-end">
              {t("price.unitPrice")}
            </th>
          </tr>
        </thead>
        <tbody>
          {!!data &&
            Object.keys(data).map((key: any, index) =>
              !displayKeys || displayKeys.indexOf(key) > -1 ? (
                <tr
                  key={`referenceCosts-${index}`}
                  onClick={onSelect.bind(null, key)}
                >
                  <td scope="col">
                    {!hasUpdateQuantity && key}
                    {hasUpdateQuantity && (
                      <InputInlineApi
                        callBack={onQuantityUpdated(`quantityRef${index + 1}`)}
                        value={key}
                        name="quantity"
                        label={t("quantity")}
                        inputProps={{
                          type: "number",
                          onKeyDown: Utils.triggerTypeNumber,
                          min: 1
                        }}
                        hiddenLoadingIcon
                      />
                    )}
                  </td>
                  <td scope="col" className="text-end">
                    <div>
                      {!quantityRefLoading[`quantityRef${index + 1}`] && (
                        <CurrencyFormat value={data[key]} />
                      )}
                      {quantityRefLoading[`quantityRef${index + 1}`] && (
                        <LoadingOutlined />
                      )}
                    </div>
                  </td>
                </tr>
              ) : (
                <></>
              )
            )}
        </tbody>
      </table>
    </div>
  );
}

export default PriceReference;
