import {
  EditOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Button,
  Card,
  Checkbox,
  Space,
  Form,
  Modal,
  Descriptions,
  Input,
  Select,
  Popover,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Address } from "../../models/Address";
import { Part } from "../../models/Part";
import { Price, PriceSurfaceTreatments } from "../../models/Price";
import { ListViewMode, Project } from "../../models/Project";
import { ShippingOption } from "../../models/ShippingOption";
import { User } from "../../models/User";
import { UserRole } from "../../models/UserRole";
import SelectCustomer from "../../pages/Customer/SelectCustomer";
import { authSelector } from "../../redux/slides/auth.slide";
import { loadingActions } from "../../redux/slides/loading.slide";
import {
  projectActions,
  projectSelector,
} from "../../redux/slides/project.slide";
import { profileSelector } from "../../redux/slides/user.slide";
import projectServices from "../../services/project.service";
import userServices from "../../services/user.service";
import AddressForm from "../Billing/AddressForm";
import CurrencyFormat from "../Format/CurrencyFormat";
import ListParts from "./ListParts";
import { useTranslation } from "react-i18next";
import { DeliveryOption } from "../../models/DeliveryOption";
import { DeliveryCost } from "../../models/DeliveryCost";
import PdfFileIcon from "../SVGs/PdfFileIcon";
import SurfaceTreatmentSurcharge from "../Project/SurfaceTreatmentSurcharge";
import TotalPartPrice from "../Project/TotalPartPrice";

interface DefaultProps {
  onSubmit?: any;
}

function CalculationBilling(props: DefaultProps) {
  const { onSubmit } = props;
  const params = useParams();
  const projectId: number = Number(params.projectId);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const auth = useSelector(authSelector);
  const dataProfile = useSelector(profileSelector);
  const { data, isCalcAll } = useSelector(projectSelector);
  const [formCheckOut] = Form.useForm();
  const [formAddress] = Form.useForm();
  const [profile, setProfile] = useState<User>(dataProfile);
  const [project, setProject] = useState<Project>(data);
  const [dataAuto, setDataAuto] = useState<Part[]>();
  const [dataManual, setDataManual] = useState<Part[]>();
  const [price, setPrice] = useState<Price>();
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [showDeliveryNote, setShowDeliveryNote] = useState(
    project?.totalWeight >= 25
  );
  const [shippingOption, setShippingOption] = useState(
    project?.totalWeight >= 25
      ? ShippingOption.SPECIAL
      : ShippingOption.STANDARD
  );
  const [deliveryOption, setDeliveryOption] = useState(
    project.deliveryOption || DeliveryOption.ECONOMY
  );
  const [billingAddress, setBillingAddress] = useState<Address>(
    project.order.billingAddress || {
        ...(project.selfCalculation ? undefined : profile?.billingAddress),
        id: undefined,
      } ||
      undefined
  );
  const [shippingAddress, setShippingAddress] = useState<Address>(
    project.order.shippingAddress || {
        ...(project.selfCalculation ? undefined : profile?.shippingAddress),
        id: undefined,
      } ||
      undefined
  );
  const [isSameShippingAddress, setIsSameShippingAddress] = useState(false);
  const [addressUpdating, setAddressUpdating] = useState(false);
  const [error, setError] = useState<any>();
  const [customer, setCustomer] = useState<User>();
  const [customerEmail, setCustomerEmail] = useState<string>();
  const [errorAddress, setErrorAddress] = useState(false);
  const [deliveryCosts, setDeliveryCosts] = useState<any>({});
  const [downloadingPreviewOrder, setDownloadingPreviewOrder] = useState(false);
  const initProjectData = () => {
    const manualParts = project.parts?.filter((p) => !p.auto);
    setDataManual(manualParts);
    if (!project.selfCalculation) {
      formCheckOut.setFieldsValue({
        id: project.id,
        shippingOption:
          project?.totalWeight >= 25
            ? ShippingOption.SPECIAL
            : project.order.shippingOption || ShippingOption.STANDARD,
      });
    }
    formAddress.setFieldsValue({
      billingAddress:
        project.order.billingAddress ||
        (project.selfCalculation ? undefined : profile?.billingAddress),
      shippingAddress:
        project.order.shippingAddress ||
        (project.selfCalculation ? undefined : profile?.shippingAddress),
    });
  };

  const getMe = async () => {
    try {
      const rs = await userServices.me();
      setProfile(rs);
    } catch (error) {}
  };

  const getPrice = async () => {
    try {
      const rs = await projectServices.getPrice({
        id: projectId,
        shippingOption,
      });
      setPrice(rs);
    } catch (error) {}
  };

  const updateDeliveryOption = async (deliveryOption: DeliveryOption) => {
    // dispatch(loadingActions.show(true));
    try {
      if (!!project) {
        await projectServices.updateDeliveryOption({
          id: project.id,
          deliveryOption,
        });
        setDeliveryOption(deliveryOption);
      }
    } catch (error) {
      setError(error);
    }
    // dispatch(loadingActions.show(false));
  };

  const initData = async () => {
    try {
      await getMe();
      await initProjectData();
    } catch (error) {}
  };
  const getDeliveryCosts = async () => {
    try {
      const rs: DeliveryCost = await projectServices.getDeliveryCosts(
        projectId
      );
      setDeliveryCosts(rs);
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    initData();
    getDeliveryCosts();
  }, []);
  useEffect(() => {
    getPrice();
  }, [shippingOption, deliveryOption]);
  useEffect(() => {
    if (isAddressModalOpen) {
      formAddress.setFieldsValue({
        billingAddress,
        shippingAddress,
      });
    }
  }, [isAddressModalOpen]);

  const showAddressModal = () => {
    setIsAddressModalOpen(true);
  };
  const handleCancel = () => {
    setIsAddressModalOpen(false);
    setIsSameShippingAddress(false);
  };

  const onFormChange = (values: any) => {
    if (!!values.shippingOption) {
      setShippingOption(values.shippingOption);
      setShowDeliveryNote(values.shippingOption === ShippingOption.SPECIAL);
    }
    if (!!values.deliveryOption) {
      updateDeliveryOption(values.deliveryOption);
    }
    if (!values.agreeTermConditions) {
      formCheckOut.setFieldValue("agreeTermConditions", undefined);
    }
  };

  const onIsSameShippingAddress = (e: CheckboxChangeEvent) => {
    setIsSameShippingAddress(e.target.checked);
    if (e.target.checked) {
      const sa = formAddress.getFieldValue("billingAddress");
      formAddress.setFieldValue("shippingAddress", {
        ...sa,
        id: shippingAddress?.id,
      });
    }
  };
  const onFormAddressSubmit = async (values: any) => {
    setAddressUpdating(true);
    try {
      const rs: Project = await projectServices.updateAddresses(values);
      dispatch(projectActions.setProject(rs));
      setBillingAddress(rs.order.billingAddress);
      setShippingAddress(rs.order.shippingAddress);
      handleCancel();
      setErrorAddress(false);
    } catch (error) {}
    setAddressUpdating(false);
  };

  const onCheckOut = async (values: any) => {
    setErrorAddress(false);
    if (!billingAddress.firstName) {
      setErrorAddress(true);
      return;
    }
    dispatch(loadingActions.show(true));
    setError(undefined);
    try {
      // await projectServices.updateAddresses({
      //   projectId: values.id,
      //   billingAddress,
      //   shippingAddress,
      // });
      const rs =
        auth.user.role === UserRole.BUYER
          ? await projectServices.checkout(values)
          : await projectServices.offerProject({
              ...values,
              email: customerEmail,
            });
      dispatch(projectActions.setProject(rs));
      if (onSubmit) onSubmit();
    } catch (error) {
      setError(error);
    }
    dispatch(loadingActions.show(false));
  };

  const onCustomerSelected = (e: any) => {
    if (typeof e === "string") {
      setCustomer(undefined);
      setCustomerEmail(e);
      formCheckOut.setFieldValue("billingAddress", undefined);
      formCheckOut.setFieldValue("shippingAddress", undefined);
      setBillingAddress({});
      setShippingAddress({});
    } else {
      if (e) {
        const address = {
          projectId,
          billingAddress: {
            ...e.billingAddress,
            id: project.order.billingAddress?.id,
          },
          shippingAddress: {
            ...e.shippingAddress,
            id: project.order.shippingAddress?.id,
          },
        };
        formCheckOut.setFieldValue("billingAddress", address.billingAddress);
        formCheckOut.setFieldValue("shippingAddress", address.shippingAddress);
        onFormAddressSubmit(address);
        setCustomer(e);
        setCustomerEmail(e.email);
      } else {
        setCustomer(undefined);
      }
    }
  };

  const onDownloadPreviewOffer = async () => {
    setErrorAddress(false);
    if (!billingAddress.firstName) {
      setErrorAddress(true);
      return;
    }
    try {
      setDownloadingPreviewOrder(true);
      await projectServices.finalizeOrder({
        id: project.id,
        shippingOption,
        shippingComment: formCheckOut.getFieldValue("shippingComment"),
        agreeTermConditions: true,
      });
      await projectServices.downloadPreviewOrder({
        id: project.id,
        name: project.name || "GOCAD",
      });
    } catch (error) {}
    setDownloadingPreviewOrder(false);
  };

  if (!project) return <></>;
  return (
    <>
      <Form
        form={formCheckOut}
        layout="vertical"
        onValuesChange={onFormChange}
        onFinish={onCheckOut}
        initialValues={{
          id: projectId,
          shippingOption,
          deliveryOption: project.deliveryOption,
        }}
      >
        <div className="row mt-4">
          <div className="col col-12 col-md-9">
            <div className="row">
              {project.selfCalculation && (
                <>
                  <div className="col-2">
                    <h6 className="fw-bold">Customer</h6>
                  </div>
                  <div className="col-10">
                    <SelectCustomer onChange={onCustomerSelected} />
                  </div>
                </>
              )}
              {((auth.user.role === UserRole.BUYER && profile?.companyName) ||
                (auth.user.role === UserRole.SELLER &&
                  customer?.companyName)) && (
                <>
                  <div className="col col-12 col-md-2 mb-md-4">
                    <label className="fw-bold">{t("companyName")}</label>
                  </div>
                  <div className="col col-12 col-md-10 mb-3 mb-md-4">
                    {auth.user.role === UserRole.BUYER && profile?.companyName}
                    {auth.user.role === UserRole.SELLER &&
                      customer?.companyName}
                  </div>
                </>
              )}
              <div className="col col-12 col-md-2">
                <label className="fw-bold">{t("billingAddress")}</label>
              </div>
              <div className="col">
                <Descriptions layout="vertical" size={"small"}>
                  <Descriptions.Item label={t("fullName")}>
                    {billingAddress?.firstName} {billingAddress?.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.houseNumber")}>
                    {billingAddress?.houseNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.streetName")}>
                    {billingAddress?.streetName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.state")}>
                    {billingAddress?.state}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.postCode")}>
                    {billingAddress?.postCode}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.city")}>
                    {billingAddress?.city}
                  </Descriptions.Item>
                </Descriptions>
                {errorAddress && (
                  <Alert
                    message={<>Address is required!</>}
                    type="error"
                    showIcon
                  />
                )}
              </div>
              <div className="col-1 d-flex justify-content-end">
                <Button
                  icon={<EditOutlined />}
                  onClick={showAddressModal}
                ></Button>
              </div>
            </div>
            <div className="col-1"></div>
            <div className="row mt-3 mt-md-4">
              <div className="col col-12 col-md-2">
                <label className="fw-bold">{t("shippingAddress")}</label>
              </div>
              <div className="col">
                <Descriptions layout="vertical" size={"small"}>
                  <Descriptions.Item label={t("fullName")}>
                    {shippingAddress?.firstName} {shippingAddress?.lastName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.houseNumber")}>
                    {shippingAddress?.houseNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.streetName")}>
                    {shippingAddress?.streetName}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.state")}>
                    {shippingAddress?.state}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.postCode")}>
                    {shippingAddress?.postCode}
                  </Descriptions.Item>
                  <Descriptions.Item label={t("address.city")}>
                    {shippingAddress?.city}
                  </Descriptions.Item>
                </Descriptions>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="row">
              <ListParts project={project} mode={ListViewMode.CHECKOUT} />
            </div>
          </div>
          <div className="col col-12 col-md-3 mt-3 mt-md-0">
            <Card className="b-shadow" title={t("project.orderSummary")}>
              <Space direction="vertical" size={8} className="w-100">
                {/* <p className="sub-l">
                  {t(`deliveryOption.${project.deliveryOption}`)}
                </p> */}
                {auth.user.role === UserRole.BUYER && (
                  <Form.Item label={t("deliveryOption")} name="deliveryOption">
                    <Select
                      options={[
                        {
                          value: DeliveryOption.ECONOMY,
                          label: `${t("deliveryOption.ECONOMY")} - ${
                            deliveryCosts[DeliveryOption.ECONOMY]
                              ?.packageDeliveryTime
                          } ${t("weeks")} (${moment()
                            .add(
                              deliveryCosts[DeliveryOption.ECONOMY]
                                ?.packageDeliveryTime,
                              "weeks"
                            )
                            .format("L")})`,
                        },
                        {
                          value: DeliveryOption.STANDARD,
                          label: `${t("deliveryOption.STANDARD")} - ${
                            deliveryCosts[DeliveryOption.STANDARD]
                              ?.packageDeliveryTime
                          } ${t("weeks")} (${moment()
                            .add(
                              deliveryCosts[DeliveryOption.STANDARD]
                                ?.packageDeliveryTime,
                              "weeks"
                            )
                            .format("L")})`,
                        },
                        {
                          value: DeliveryOption.FAST,
                          label: `${t("deliveryOption.FAST")} - ${
                            deliveryCosts[DeliveryOption.FAST]
                              ?.packageDeliveryTime
                          } ${t("weeks")} (${moment()
                            .add(
                              deliveryCosts[DeliveryOption.FAST]
                                ?.packageDeliveryTime,
                              "weeks"
                            )
                            .format("L")})`,
                        },
                      ]}
                    />
                  </Form.Item>
                )}
                <div>
                  {/* <p className="sub-l">{t("project.shippingOptions")}</p> */}
                  <Form.Item
                    label={t("project.shippingOptions")}
                    name="shippingOption"
                  >
                    <Select
                      // disabled={project?.totalWeight >= 25}
                      options={[
                        {
                          value: ShippingOption.STANDARD,
                          label: t("shippingOption.STANDARD"),
                        },
                        {
                          value: ShippingOption.SELF_PICK_UP,
                          label: t("shippingOption.SELF_PICK_UP"),
                        },
                        {
                          value: ShippingOption.SPECIAL,
                          label: t("shippingOption.SPECIAL"),
                        },
                      ]}
                    />
                  </Form.Item>
                  {showDeliveryNote && (
                    <div>
                      <Alert
                        className="mb-2"
                        message={t("shippingOption.SPECIAL.note")}
                        banner
                      />
                    </div>
                  )}
                  <Form.Item
                    className="mb-1"
                    label={t("project.label.shippingOption")}
                    name="shippingComment"
                  >
                    <TextArea maxLength={255}></TextArea>
                  </Form.Item>
                </div>
                <hr />
                <div className="summary-price">
                  <TotalPartPrice price={price}/>
                  <SurfaceTreatmentSurcharge price={price}/>
                  <div className="d-flex">
                    <label>
                      {t("price.deliveryOptionPrice")} (
                      {t(`deliveryOption.${deliveryOption}`)})
                    </label>
                    <label className="ms-auto">
                      <CurrencyFormat value={price?.deliveryOptionPrice} />
                    </label>
                  </div>
                  <div className="d-flex">
                    <label>{t("price.packagingPrice")}</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={price?.packagingPrice} />
                    </label>
                  </div>
                  <div className="d-flex shipping-cost">
                    <label>{t("price.shipping")}</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={price?.shipping} />
                    </label>
                  </div>
                  <hr className="my-1" />
                  <div className="d-flex fw-bold">
                    <label>{t("price.netTotal")}</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={price?.netTotal} />
                    </label>
                  </div>
                  <div className="d-flex">
                    <label>{t("price.vat")}</label>
                    <label className="ms-auto">
                      <CurrencyFormat value={price?.vat} />
                    </label>
                  </div>
                  <hr className="my-1" />
                  <div className="d-flex fw-bold">
                    <label>{t("price.total")}</label>
                    <label className="ms-auto total-price">
                      <CurrencyFormat value={price?.total} />
                    </label>
                  </div>
                </div>
                <hr className="my-1" />
                <div>
                  <a onClick={onDownloadPreviewOffer}>
                    <PdfFileIcon /> {t("project.checkout.previewOffer")}{" "}
                    {downloadingPreviewOrder && <LoadingOutlined spin />}
                  </a>
                </div>
                <div className="mt-3">
                  <Form.Item name="id" hidden>
                    <Input />
                  </Form.Item>
                  {!project.selfCalculation && (
                    <Form.Item
                      name="agreeTermConditions"
                      valuePropName="checked"
                      rules={[
                        {
                          required: true,
                          message: t("project.checkout.tnc.required") || "",
                        },
                      ]}
                    >
                      <Checkbox>{t("project.checkout.tnc")}</Checkbox>
                    </Form.Item>
                  )}
                  {error && (
                    <div className="mb-3">
                      <Alert type="error" message={error.message} showIcon />
                    </div>
                  )}
                  <Form.Item className="mb-0">
                    <Button type="primary" htmlType="submit" block>
                      {project.selfCalculation
                        ? t("project.sendOutOffer")
                        : t("project.placeYourOrder")}
                    </Button>
                  </Form.Item>
                </div>
                {/* <div className="mt-3 row payment-logo">
                  <img src="/images/paypal-logo.png" />
                  <img src="/images/vorkasse_no_bg.png" />
                  <img src="/images/Visa_2021.svg.png" />
                  <img src="/images/master-card-logo.png" />
                </div> */}
              </Space>
            </Card>
            {
              <Alert
                className="mt-3"
                description={
                  <>
                    <ul className="mb-0">
                      <li>{t("project.checkout.manual.note1")}</li>
                      {((dataManual && !!dataManual.length) ||
                        (!project.manual && project.linkedProject)) && (
                        <li>{t("project.checkout.manual.note2")}</li>
                      )}
                    </ul>
                  </>
                }
                type="info"
                showIcon
                icon={<InfoCircleOutlined />}
              />
            }
          </div>
        </div>
      </Form>
      <Modal
        // title="Part"
        open={isAddressModalOpen}
        onCancel={handleCancel}
        onOk={formAddress.submit}
        okButtonProps={{
          loading: addressUpdating,
        }}
        centered
        width={"80vw"}
        destroyOnClose
      >
        <Form
          className="app-form"
          form={formAddress}
          layout="vertical"
          onFinish={onFormAddressSubmit}
          initialValues={{
            projectId,
          }}
          disabled={addressUpdating}
        >
          <Form.Item name="projectId" hidden>
            <Input />
          </Form.Item>
          <Space direction="horizontal" align="start">
            <div className="row">
              <div className="col-6">
                <AddressForm
                  form={formAddress}
                  data={billingAddress}
                  title={t("billingAddress")}
                  name="billingAddress"
                  required
                />
                <Checkbox
                  onChange={onIsSameShippingAddress}
                  defaultChecked={isSameShippingAddress}
                >
                  {t("address.same.note")}
                </Checkbox>
              </div>
              <div className="col-6">
                <AddressForm
                  form={formAddress}
                  data={shippingAddress}
                  title={t("shippingAddress")}
                  name="shippingAddress"
                  required
                  disabled={isSameShippingAddress}
                />
              </div>
            </div>
            {isSameShippingAddress && (
              <Form.Item name="shippingAddress" hidden>
                <Input />
              </Form.Item>
            )}
          </Space>
        </Form>
      </Modal>
    </>
  );
}

export default CalculationBilling;
