import { Popover } from "antd";
import { Price } from "app/models/Price";
import CurrencyFormat from "../Format/CurrencyFormat";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

interface DefaultProps {
  price?: Price;
  lableClass?: string;
}

function SurfaceTreatmentSurcharge(props: DefaultProps) {
  const { price, lableClass } = props;
  const { t } = useTranslation();

  return (
    <>
      {!!price?.surfaceTreatmentSurcharge && (
        <div className="d-flex">
          <Popover
            overlayStyle={{
              maxWidth: "20vw",
            }}
            placement="top"
            content={
              <>
                {t("price.surfacetreatments.note")}
                <div>
                  <table className="table mb-0 text-white">
                    <thead>
                      <tr>
                        <th scope="col">{t("method")}</th>
                        <th scope="col" className="text-end">
                          {t("price")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {price?.priceSurfaceTreatments &&
                        price?.priceSurfaceTreatments.map((item, index) => (
                          <tr key={`surfacetreatment-${index}`}>
                            <td scope="col">{item.method}</td>
                            <td scope="col" className="text-end">
                              <CurrencyFormat value={item.differenceCost} />
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            }
            trigger="hover"
          >
            <label className={lableClass ?? lableClass}>
              {t("price.surfacetreatments")}: <InfoCircleOutlined />
            </label>
          </Popover>
          <label className="ms-auto">
            <CurrencyFormat value={price?.surfaceTreatmentSurcharge} />
          </label>
        </div>
      )}
    </>
  );
}

export default SurfaceTreatmentSurcharge;
