/* eslint-disable jsx-a11y/iframe-has-title */
import { HTMLAttributes, useEffect, useRef, useState } from "react";
import {
  Dropdown,
  ImageProps,
  MenuProps,
  Modal,
  Space,
  Typography,
} from "antd";
import "./cadFileItem.module.scss";
import axios from "axios";
import { DxfParser, ILayer } from "dxf-parser";
import { DxfViewer } from "dxf-viewer";
import * as three from "three";
import montserrat from "../../../assets/fonts/Montserrat-Regular.ttf";
import { Part } from "../../models/Part";
import Tree, { DataNode } from "antd/es/tree";
import { useTranslation } from "react-i18next";
import partServices from "app/services/part.service";
import {
  CaretDownOutlined,
  DownOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { LayerType } from "app/models/LayerType";
import { log } from "console";

interface DefaultProps extends HTMLAttributes<any> {
  part: Part;
  selectedLayers?: any;
  onLayerLoaded?: any;
  onSelectedLayers?: any;
  onDxfLayerLoad?: any
}

function DxfSelectLayer(props: DefaultProps) {
  const { part, onLayerLoaded, onSelectedLayers, onDxfLayerLoad } = props;
  const { t } = useTranslation();
  const [isOpenDxfPreview, setIsOpenDxfPreview] = useState(false);
  const [key] = useState(Date.now());
  const [loading, setLoading] = useState(true);
  const [layers, setLayers] = useState<ILayer[]>();
  const [dataLayers, setDataLayers] = useState<DataNode[]>([]);
  const [checkedKeys, setCheckedKeys] = useState<string[]>([]);
  const [selectedLayers, setSelectedLayers] = useState<any[]>(
    Object.keys(props.selectedLayers || {}) || []
  );
  const [dxf, setDxf] = useState<any>();
  const [dxfViewer, setDxfViewer] = useState<DxfViewer>();
  const [dxfBlobUrl, setDxfBlobUrl] = useState<string | null>(null);
  const [layerTypeSelected, setLayerTypeSelected] = useState<any>({
    ...props.selectedLayers,
  });

  const canvasRef = useRef<any>(null);

  const getDxfContent = async () => {
    setLoading(true);
    if (part && part.partFileName) {
      const dxfParser = new DxfParser();
      try {
        const rs = await partServices.getDxfContent(part?.id);
        const data: string = rs;
        const dxf = dxfParser.parseSync(data);
        setDxf(dxf);
        const layers = dxf?.tables.layer.layers;
        let checkedKeys: any[] = [];
        if (layers) {
          const l = Object.keys(layers).map((l) => {
            layers[l].visible =
              selectedLayers && selectedLayers.length > 0
                ? selectedLayers.findIndex((s) => s == layers[l].name) > -1
                  ? true
                  : false
                : true;
            if (layers[l].visible) {
              checkedKeys.push(layers[l].name);
            }
            if (!layerTypeSelected[layers[l].name]) {
              layerTypeSelected[layers[l].name] = LayerType.CUT;
            }
            return layers[l];
          });
          setLayerTypeSelected(layerTypeSelected);
          setCheckedKeys(checkedKeys);
          setLayers(l);
          if (onLayerLoaded)
            onLayerLoaded(["all-layers", ...l.map((l) => l.name)]);
          if (onDxfLayerLoad) {
            onDxfLayerLoad(layerTypeSelected)
          }
        }
        const blob = new Blob([data], { type: "application/dxf" });
        const blobUrl = URL.createObjectURL(blob);
        setDxfBlobUrl(blobUrl);
      } catch (error) {
        // console.log(error);
      }
      setLoading(false);
    }
  };

  const renderToCanvas = async (dxf: any) => {
    const canvas = canvasRef.current;
    const option: any = {
      clearColor: new three.Color("#fff"),
      autoResize: true,
      colorCorrection: true,
      sceneOptions: {
        wireframeMesh: true,
      },
    };
    if (canvas) {
      const viewer = new DxfViewer(canvas, option);
      const loadData: any = {
        url: dxfBlobUrl,
        fonts: [montserrat],
        worerFactory: DxfViewer.SetupWorker(),
      };
      await viewer.Load(loadData);
      if (layers) {
        layers.map((l: any) => {
          viewer.ShowLayer(
            l.name,
            selectedLayers && selectedLayers.length > 0
              ? selectedLayers.findIndex((s) => s == l.name) > -1
                ? true
                : false
              : true
          );
        });
      }
      setDxfViewer(viewer);
    }
  };
  const items: MenuProps["items"] = [
    {
      key: LayerType.CUT,
      label: LayerType.CUT,
    },
    {
      key: LayerType.FOLD,
      label: LayerType.FOLD,
    },
    {
      key: LayerType.MARK,
      label: LayerType.MARK,
    },
  ];
  useEffect(() => {
    if (layers) {
      const l = layers.map((l) => {
        return {
          title: (
            <div className="d-flex">
              {l.name}
            </div>
          ),
          key: l.name,
        };
      });
      const treeData: DataNode[] = [
        {
          title: "All layers",
          key: "all-layers",
          children: l,
        },
      ];
      setDataLayers(treeData);
    }
  }, [layers]);
  useEffect(() => {
    if (part && part.partFileName) {
      getDxfContent();
    }
  }, [part]);

  useEffect(() => {
    if (isOpenDxfPreview) {
      if (selectedLayers && selectedLayers.length > 0)
        setCheckedKeys(selectedLayers);
      renderToCanvas(dxf);
    } else {
    }
  }, [isOpenDxfPreview]);

  const onCheck = (checkedKeysValue: any) => {
    setCheckedKeys(checkedKeysValue);
    if (dxfViewer && layers) {
      layers.map((l) => {
        dxfViewer.ShowLayer(l.name, checkedKeysValue.indexOf(l.name) > -1);
      });
    }
  };

  const onSelect = (selectedKeysValue: any[], info: any) => {
    const value: string = info.node.key;
    const i = checkedKeys.findIndex((l) => l == value);
    let values: string[] = [...checkedKeys];
    if (i > -1) {
      values.splice(i, 1);
      dxfViewer?.ShowLayer(value, false);
    } else {
      values.push(`${value}`);
      dxfViewer?.ShowLayer(value, true);
    }
    setCheckedKeys(values);
  };

  const onOk = () => {
    if (checkedKeys.length > 0) {
      const ls = checkedKeys;
      const data: any = {};
      checkedKeys.map((k) => {
        data[k] = layerTypeSelected[k];
      });
      if (onSelectedLayers) onSelectedLayers(data);
      setSelectedLayers(ls);
    } else {
      const ls: any = layers?.map((l) => l.name);
      const data: any = {};
      checkedKeys.map((k) => {
        data[k] = layerTypeSelected[k];
      });
      ls.push("all-layers");
      if (onSelectedLayers) onSelectedLayers(data);
      setSelectedLayers(ls);
      setCheckedKeys(checkedKeys);
    }

    setIsOpenDxfPreview(false);
  };

  const onCancel = () => {
    setSelectedLayers(Object.keys(props.selectedLayers));
    setIsOpenDxfPreview(false);
  };

  const onLayerTypeChanged = (name: string) => (e: any) => {
    setLayerTypeSelected({
      ...layerTypeSelected,
      [name]: e.key,
    });
  };

  if (!part || loading)
    return (
      <>
        <LoadingOutlined />
      </>
    );

  return (
    <>
      <div className="pointer" onClick={setIsOpenDxfPreview.bind(null, true)}>
        {(selectedLayers?.length === 0 ||
          selectedLayers?.findIndex((l) => l === "all-layers") > -1) && (
          <a className="text-primary">All layers</a>
        )}
        {selectedLayers?.length > 0 &&
          selectedLayers.findIndex((l) => l === "all-layers") === -1 && (
            <a className="text-primary">{selectedLayers.join(", ")}</a>
          )}
      </div>
      <Modal
        open={isOpenDxfPreview}
        width={"calc(80vw - 46px)"}
        centered
        className="v3dmodel"
        maskClosable={false}
        okButtonProps={{
          className: "me-3 mb-3",
        }}
        onOk={onOk}
        onCancel={onCancel}
      >
        <div className="row w-100">
          <div className="col col-3 py-4 layers">
            <h6 className="mb-3">{t("part.cuttingLayers")}</h6>
            <div className="row">
              <div className="col">
                <Tree
                  style={{
                    background: "transparent",
                  }}
                  checkable
                  expandedKeys={["all-layers"]}
                  autoExpandParent={true}
                  onCheck={onCheck}
                  checkedKeys={checkedKeys}
                  treeData={dataLayers}
                  onSelect={onSelect}
                  blockNode
                />
              </div>
              <div className="col-3 d-flex flex-column align-items-end">
                <div className="layer-type"></div>
                {layers?.map((l, i) => (
                  <div className="layer-type" key={`layer-type-${i}`}>
                    <Dropdown
                      // className="ms-auto text-primary"
                      trigger={["click"]}
                      menu={{
                        items,
                        selectable: true,
                        defaultSelectedKeys: [LayerType.CUT],
                        onSelect: onLayerTypeChanged(l.name),
                      }}
                      disabled={checkedKeys.indexOf(l.name) === -1}
                    >
                      <Typography.Link>
                        <Space>
                          <span>{layerTypeSelected[l.name]}</span>
                          <CaretDownOutlined />
                        </Space>
                      </Typography.Link>
                    </Dropdown>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="col col-9">
            <div ref={canvasRef} className="canvasContainer"></div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default DxfSelectLayer;
